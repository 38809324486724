$max-width: 1440px;
$indent__base: 20px;

$perspective: 850px;
$present-dimension: 12em;
$date-dimension: $present-dimension / 5;
$bauble-dimension: $present-dimension / 4.5;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap");

:root {
  --background: #defff4;
  --butterfly-mid: #372f23;
  --butterfly-wings: #09a0ce;
  --butterfly2: #329c32;
}

.wrapper {
  max-width: $max-width;
  margin: $indent__base auto;
}

.calendar {
  &__inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $indent__base;
  }

  &__item {
    position: relative;
    display: inline-block;
    width: 100%;
    perspective: $perspective;
    z-index: 20;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    .door {
      position: absolute;
      background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #d2dfe1 100%);
      display: flex;
      align-items: center;
      justify-content: center;
      transform-style: preserve-3d;
      transform-origin: 0;
      perspective: 850px;
      text-align: center;
      transition: all 0.2s ease-out;
      width: inherit;
      height: 100%;
      top: 0;
      z-index: 1;
    }

    img {
      max-width: 100%;
      max-height: 75%;
      height: auto;
      object-fit: contain;
    }

    .content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 20px;
      background: #eff4f4;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        margin-top: auto;

        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      h3 {
        font-family: Raleway, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #007180;
        margin-top: auto;
        margin-bottom: auto;

        @media screen and (max-width: 480px) {
          font-size: 15px;
        }
      }

      .button {
        outline: 0;
        padding: 12px 64px;
        background: #f08a00;
        border-radius: 4px;
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        border: 0;
        cursor: pointer;
        margin-bottom: auto;
        white-space: nowrap;

        @media screen and (max-width: 480px) {
          padding: 8px 32px;
        }

        &:hover {
          background-color: #c57301;
        }
      }
    }

    &.active {
      &.current {
        .butterflies-wrapper {
          width: 100%;
          height: 100%;
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
        }

        .createbutterfly {
          position: absolute;
          z-index: 2;
        }

        .content {
          overflow: hidden;
        }

        &:hover .butterflies-wrapper {
          z-index: -1;
        }
      }

      &:hover {
        filter: drop-shadow(1px 1px 3px #c7c7c7);
        z-index: 999;

        .door {
          transform: rotateY(-97deg);
          perspective-origin: 0;
          transition: all 0.25s ease-in;

          img {
            backface-visibility: hidden;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .calendar {
    &__inner {
      grid-gap: $indent__base / 2;
    }
  }
}

@media screen and (max-width: 1024px) {
  .calendar {
    &__inner {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (max-width: 768px) {
  .calendar {
    &__inner {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 320px) {
  .calendar {
    &__inner {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.flying {
  position: absolute;
  display: block;
  animation: fly-high 5s infinite ease-in-out;
  transform-origin: 100%;
  top: 10%;
  left: 20%;
  transform-style: preserve-3d;
  transform: rotateX(50deg) rotateY(30deg) rotateZ(-60deg);
  width: 100px;
  height: 100px;
}

.wing-left,
.wing-right {
  position: absolute;
  display: block;
}

.wing-left {
  animation: move-left 0.4s infinite;
  animation-direction: alternate;
}

.wing-right {
  animation: move-right 0.4s infinite;
  animation-direction: alternate;
}

.fly,
.fly::after {
  background: var(--butterfly-wings);
  transform-origin: 100% 50%;
  overflow: hidden;
  position: absolute;
  display: block;
  border-radius: 50% 50% 40% 40%;
  right: 0;
  top: 0;
}

.fly:first-child {
  height: 1.7em;
  text-align: center;
  transform: rotateZ(30deg);
  width: 2.8em;
}

.fly:last-child {
  height: 1.3em;
  transform: rotateZ(-30deg);
  width: 2.5em;
}

.flying-pink {
  position: relative;
  display: block;
  top: 10%;
  left: 20%;
  transform-style: preserve-3d;
  transform: rotateX(50deg) rotateY(30deg) rotateZ(-60deg);
  width: 100px;
  height: 100px;
  animation: fly-high 5s linear infinite;
}

.fly-pink,
.fly-pink::after {
  background: var(--butterfly2);
  transform-origin: 100% 50%;
  position: absolute;
  display: block;
  border-radius: 50% 50% 25% 25%;
  right: 0;
  top: 0;
}

.fly-pink:first-child {
  height: 1.7em;
  text-align: center;
  transform: rotateZ(30deg);
  width: 2.8em;
}

.fly-pink:last-child {
  height: 1em;
  transform: rotateZ(-30deg);
  width: 2em;
}

@keyframes move-left {
  0% {
    transform: rotateY(-60deg);
  }

  100% {
    transform: rotateY(60deg);
  }
}

@keyframes move-right {
  0% {
    transform: rotateY(170deg);
  }

  100% {
    transform: rotateY(70deg);
  }
}

@keyframes fly-high {
  0% {
    left: -100px;
    top: 50px;
  }

  25% {
    left: -100px;
    top: 100px;
  }

  50% {
    left: -100px;
    top: 50px;
  }

  100% {
    left: -100px;
    top: 50px;
  }
}
